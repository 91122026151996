.bgt-box__image-container {
  text-align: center;
}

.bgt-ckeditor-container {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: .14em;
  line-height: 2.1875;
  @include mq(sp) {
    letter-spacing: 0;
    line-height: 2.1428
  }
  a {
    text-decoration: underline;
  }
}

.flex-block {
  display: flex;
  flex-wrap: wrap;
  .flex-grid {
    width: 23%;
    margin-right: 2.6666%;
    margin-bottom: 40px;
    @include mq(sp) {
      width: 48%;
      margin-right: 4%;
      margin-bottom: 20px;
    }
    &:nth-child(4n) {
      @include mq(pc) {
        margin-right: 0;
      }
    }
    &:nth-child(2n) {
      @include mq(sp) {
        margin-right: 0;
      }
    }
    .bgt-image-container {
      margin-bottom: 10px;
    }
    .bgt-ckeditor-container {
      line-height: 1.3;
    }
  }
}

[data-bgb] {
  table {
    @include box-sizing(border-box);
    margin-bottom: 60px;
    border-top: 1px solid $main;
    tr {
      border-bottom: 1px solid $main;
    }
    th {
      @include box-sizing(border-box);
      padding: 20px;
      background: $light-main;
      color: $black;
      font-size: 16px;
      text-align: left;
      vertical-align: top;
      white-space: nowrap;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 10px 15px;
      }
    }
    td {
      @include box-sizing(border-box);
      padding: 20px;
      font-size: 15px;
      line-height: 1.5;
      @include mq(sp) {
        display: block;
        width: 100%;
        padding: 15px 0;
        font-size: 15px;
      }
    }
  }
}


[data-bgb=button2] .bgt-grid:first-child, [data-bgb=download-file2] .bgt-grid:first-child, [data-bgb=image2] .bgt-grid:first-child, [data-bgb=image-link2] .bgt-grid:first-child, [data-bgb=image-link-text2] .bgt-grid:first-child, [data-bgb=image-text2] .bgt-grid:first-child, [data-bgb=trimmed-image2] .bgt-grid:first-child, [data-bgb=trimmed-image-link2] .bgt-grid:first-child, [data-bgb=wysiwyg2] .bgt-grid:first-child {
    padding-right: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-right: 0;
    }
}
[data-bgb=button2] .bgt-grid:nth-child(2), [data-bgb=download-file2] .bgt-grid:nth-child(2), [data-bgb=image2] .bgt-grid:nth-child(2), [data-bgb=image-link2] .bgt-grid:nth-child(2), [data-bgb=image-link-text2] .bgt-grid:nth-child(2), [data-bgb=image-text2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image2] .bgt-grid:nth-child(2), [data-bgb=trimmed-image-link2] .bgt-grid:nth-child(2), [data-bgb=wysiwyg2] .bgt-grid:nth-child(2) {
    padding-left: 30px;
    @include mq(sp) {
      margin-bottom: 60px;
      padding-left: 0;
    }
}

/*--------------------------------------------------------
  ボタン
----------------------------------------------------------*/

.btn-def {
  text-align: center;
  a {
    display: inline-block;
    padding: 20px 50px 22px;
    border-radius: 40px;
    background: $light-main;
    font-size: 20px;
    font-weight: 400;
    line-height: 1.2;
    @include mq(sp) {
      padding: 10px 30px 12px;
      font-size: 18px;
    }
    &:hover {
      @include mq(pc) {
        opacity: .8;
      }
    }
  }
}

.btn-sub {
  text-align: center;
  a {
    display: inline-block;
    padding: 14px 40px 16px;
    background: $light-sub;
    color: white;
    font-size: 17px;
    line-height: 1.2;
    &:hover {
      @include mq(sp) {
        opacity: .8;
      }
    }
  }
}